<!--  -->
<template>
  <div class="content">
       <top-title>
      <span slot="left">我的消息</span>
      <span slot="right">我的消息</span>
    </top-title>
    <div class="data">
          <el-button type="primary" size='mini' @click="$router.push({name:'message'})" icon="el-icon-arrow-left" style="margin-bottom:10px" >返回 </el-button>
        <div class="title" style="text-align:center">
          标题
        </div>  
        <div class="message">
           <p>
            　平安中国建设表彰大会15日上午在京举行。郭声琨参加会见并在表彰大会上讲话。他指出，要把习近平总书记的亲切关怀转化为强大动力，坚持以习近平新时代中国特色社会主义思想为指导，全面贯彻落实党的十九大和十九届历次全会精神及中央经济工作会议精神，从党的百年奋斗中汲取智慧力量，深入推进更高水平的平安中国建设，确保政治更安全、社会更安定、人民更安宁，以优异成绩迎接党的二十大胜利召开，为全面建设社会主义现代化国家、实现中华民族伟大复兴的中国梦作出新的更大贡献。
　　        丁薛祥、杨晓渡、陈希、黄坤明、肖捷参加会见，赵克志、周强、张军参加会见并出席大会。
           </p>
           <p>　平安中国建设表彰大会15日上午在京举行。郭声琨参加会见并在表彰大会上讲话。他指出，要把习近平总书记的亲切关怀转化为强大动力，坚持以习近平新时代中国特色社会主义思想为指导，全面贯彻落实党的十九大和十九届历次全会精神及中央经济工作会议精神，从党的百年奋斗中汲取智慧力量，深入推进更高水平的平安中国建设，确保政治更安全、社会更安定、人民更安宁，以优异成绩迎接党的二十大胜利召开，为全面建设社会主义现代化国家、实现中华民族伟大复兴的中国梦作出新的更大贡献。
        　　会上，平安中国建设140个先进集体、129名先进个人受到表彰，授牌命名60个平安中国建设示范市和160个平安中国建设示范县，授予73个市县“长安杯”。4位受表彰代表进行交流发言。
        　　丁薛祥、杨晓渡、陈希、黄坤明、肖捷参加会见，赵克志、周强、张军参加会见并出席大会。</p>
        </div>
    </div>
  </div>
</template>

<script>
import TopTitle from "@/components/common/top-title";
export default {
  data () {
    return {
    };
  },

  components: {
    TopTitle,
  },

  computed: {},

  methods: {}
}

</script>
<style lang='scss' scoped>
.data{
    padding: 20px 20px 40px;
    background: #fff;
    
    .title{
        width: 100%;
        margin-bottom: 20px;
        font-size: 18px;
        position: relative;

        span{
            flex:1;
            letter-spacing: 1px;
            user-select:text;
        }
       
    }
    .message{
        p{  
            margin-bottom: 10px;
            text-indent: 2em;
            font-size: 15px;
            line-height: 1.6;
            user-select:text;

        }
    }
}
</style>